.host {
  font-size: 0.875rem;
  position: relative;
  border-radius: var(--app-border-radius);
}

.danger {
  color: var(--error-color);
}

.progressbar {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -12px;
  margin-left: -12px;
}
