.host {
  z-index: 2;
  background-color: transparent;
}

.pageTitle {
  flex-grow: 1;
}

.userTitle,
.profileButton {
  font-weight: 600;
}

.button {
  font-weight: 600;
  min-width: 88px;
  margin-right: 8px;
  border-radius: 2px;
}

.button:last-child {
  margin-right: 0;
}
