.host {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: inherit;
  text-align: center;
  height: calc(100vh - 59px);
}

.error404 {
  display: flex;
  justify-content: center;
}

.icon {
  width: 64px;
  height: 64px;
}

.button {
  margin-top: 24px;
}
