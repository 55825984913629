.host .label {
  margin-bottom: 8px;
  font-weight: 600;
  transform: scale(1);
  pointer-events: auto;
}

.host [class*="MuiInputBase-root"] {
  background: var(--main-background);
}

.label + div {
  margin-top: 22px;
}

.host [class*="notchedOutline"] {
  top: 0;
}

.host legend {
  display: none;
}
