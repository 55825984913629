.container {
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  flex-shrink: 0;
}

.menu {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.link > span:first-child {
  font-size: 0.875rem;
  color: var(--primary-color);
}

.navItem {
  padding-top: 12px;
  padding-bottom: 12px;
}

.logOutButton {
  position: absolute;
  left: 0;
  bottom: 0;
}
