.container {
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  flex-shrink: 0;
}

.content {
  margin-top: 15px;
}

.infoProfile,
.nameProfile {
  font-size: 0.875rem;
}

.nameProfile {
  opacity: 0.5;
  padding-left: 12px;
  padding-right: 8px;
}

.buttonWrapper {
  padding: 24px;
}

.button {
  padding: 8px 24px;
}

@media (min-width: 360px) {
  .nameProfile {
    padding-left: 24px;
    padding-right: 4px;
  }
}
