.card {
  padding: 8px 16px 12px;
  border: 0;
  overflow: visible;
}

.cardContent:last-child {
  padding-top: 4px;
  padding-bottom: 4px;
}

.itemTitle {
  font-weight: 600;
}
