.noDataText {
  color: var(--main-text-color);
}

.table {
  border: none;
}

.table [class*="MuiDataGrid-row"]:hover {
  background-color: rgba(32, 131, 165, 0.06);
}

.table [class*="columnSeparator"] {
  display: none;
}

.table [class*="columnHeaderTitle"] {
  font-size: 0.75rem;
  font-weight: 600;
  padding-left: 0;
  padding-right: 0;
}

.table [class*="MuiDataGrid-cell"] {
  border-bottom: none;
}

.table.zebra [class*="Mui-even MuiDataGrid-row"] {
  background-color: var(--even-row-background);
}

.table:not(.noPagination) [class*="footerContainer"] {
  border-top: 1px solid var(--table-border-color);
}

.progressbar {
  position: absolute;
  top: 0;
  width: 100%;
}
