.container {
  width: 100%;
  max-width: 470px;
}

.actions {
  justify-content: center;
  padding: 8px 0 32px;
}

.title {
  font-weight: 600;
  font-size: 1.75rem;
  margin-bottom: 4px;
}

@media (min-width: 600px) {
  .host [class*="MuiDialog-paper"] {
    max-width: 496px;
  }

  .container {
    padding: 0 24px;
  }
}
