.container {
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  flex-shrink: 0;
}

.controlsWrapper {
  padding: 16px 16px 0 16px;
  overflow-y: auto;
  width: 100%;
  margin: 0;
}
