@import "variables.css";

html > body {
  background: var(--main-background);
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.app-wrapper {
  color: var(--main-text-color);
}

.app-wrapper a:not([class*="Mui"]) {
  color: var(--primary-color);
}

.app-wrapper .MuiInput-formControl {
  font-size: 0.875rem;
}

.app-wrapper .MuiOutlinedInput-root {
  border-radius: var(--app-border-radius);
}

.app-wrapper .MuiOutlinedInput-adornedEnd {
  padding-right: 4px;
}

.app-wrapper .MuiOutlinedInput-input {
  padding-top: 12px;
  padding-bottom: 12px;
  min-height: 20px;
}

.app-wrapper .MuiOutlinedInput-notchedOutline {
  border-color: var(--app-border-color);
}

.app-wrapper input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
  -webkit-box-shadow: inset 0 0 0 1000px var(--main-background);
}

.inputIcon {
  fill: rgba(0, 0, 0, 0.5);
}

.MuiFormLabel-root {
  color: var(--main-color);
  font-size: 0.875rem;
  font-weight: 600;
}

.greyPaper {
  background-color: var(--main-background);
}

.MuiPaper-rounded {
  border-radius: var(--app-border-radius);
}

.MuiButton-root {
  text-transform: none;
}

.paperPadding {
  padding: 24px;
}

.emptyGrid.MuiGrid-item {
  padding: 0;
}
