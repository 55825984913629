.itemWrapper {
  padding: 12px;
}

.dataWrapper:nth-last-child(-n+2) p {
  margin-bottom: 0;
}

.namePayment,
.infoPayment {
  font-size: 0.875rem;
}

.namePayment {
  opacity: 0.5;
}

.infoPayment {
  max-width: 180px;
  padding: 0 8px;
}

.actionWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}

.actionWrapper a {
  border-radius: 4px;
}

@media (min-width: 360px) {
  .itemWrapper {
    padding: 12px 24px;
  }
}
