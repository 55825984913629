:root {
  --main-text-color: #4a4a4a;
  --main-background: #fff;
  --appbar-background: #15333d;
  --main-color: #2e384d;
  --lignt-color: rgb(255, 255, 255, 0.8);
  --primary-color: #2083a5;
  --secondary-color: #3a4656;
  --error-color: #b3251b;
  --info-color: #033a3a;
  --input-background: #e2e8ed;
  --table-border-color: #e0e0e0;
  --app-bar-height: 64px;
  --app-bar-mobile-height: 56px;
  --app-border-radius: 8px;
  --app-border-color: #dbdfe3;
  --even-row-background: #f3f5f7;
  --font-primary: 'Roboto', 'Helvetica Neue', 'Arial', 'sans-serif';
  --font-secondary: 'Manrope', 'Roboto', 'Helvetica Neue', 'Arial', 'sans-serif';
}
