.host {
  width: 100%;
}

.button {
  text-align: left;
  border-radius: var(--app-border-radius);
  border-color: var(--app-border-color);
  color: currentColor;
}

.button[class*="selected"] {
  background-color: var(--main-background);
  font-weight: 600;
  color: currentColor;
}

.paymentItem {
  width: 100%;
  display: flex;
  align-items: center;
  text-transform: none;
}

.iconPlaceholder {
  display: flex;
  min-width: 36px;
  margin-left: 4px;
  text-align: center;
}

.paymentName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.maxWidth {
  max-width: calc(100vw - 196px);
}

.paymentData {
  flex-shrink: 0;
  white-space: pre;
}

.host [class*="groupedVertical"]:not(:first-child) {
  margin-top: 0;
}

@media (min-width: 600px) {
  .maxWidth {
    max-width: 150px;
  }
}
