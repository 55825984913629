.host {
  padding: 8px;
  margin-bottom: 16px;
}

.host,
.host:first-child {
  border-radius: var(--app-border-radius);
}

.summary {
  display: flex;
  align-items: center;
}

.icon {
  background-color: #cfd4d9;
  width: 40px;
  height: 40px;
  border-radius: var(--app-border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading {
  font-weight: 800;
  font-size: 1.125rem;
  padding-left: 16px;
}
