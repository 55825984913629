.host [class*="MuiToggleButton-root"] {
  padding: 4px 12px;
  color: inherit;
}

.host [class*="MuiToggleButton-root"]:first-child {
  border-radius: var(--app-border-radius) 0 0 var(--app-border-radius);
}

.host [class*="MuiToggleButton-root"]:last-child {
  border-radius: 0 var(--app-border-radius) var(--app-border-radius) 0;
}

.host [class*="Mui-selected"] {
  background-color: var(--primary-color);
  color: #fff;
}

.host [class*="Mui-selected"]:hover {
  background-color: var(--primary-color);
  opacity: 0.9;
}

.host .label {
  margin-bottom: 8px;
  font-weight: 600;
  pointer-events: auto;
}

@media (min-width: 600px) {
  .host [class*="MuiToggleButton-root"] {
    padding: 9px 16px;
  }
}
