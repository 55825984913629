.container {
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  flex-shrink: 0;
}

.listWrapper {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-top: 0;
}

.listWrapper li:nth-child(even) {
  background-color: var(--even-row-background);
}

.noDataText {
  padding: 16px;
  text-align: center;
}
