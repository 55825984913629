.host {
  position: relative;
  z-index: 1;
}

.host > [class*="MuiPaper"] {
  width: 100%;
  border-left: none;
  flex-shrink: 0;
  overflow: hidden;
  box-shadow: -1px 0 2px 0 rgba(0, 0, 0, 0.15);
}

@media (min-width: 600px) {
  .host > [class*="MuiPaper"] {
    width: 400px;
    height: 100%;
  }
}
