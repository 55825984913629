.form {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.controlsWrapper {
  padding: 16px 24px 0;
}

.buttonWrapper {
  margin-top: auto;
  width: 100%;
}

.buttons {
  padding: 24px;
}
