.logo {
  object-fit: contain;
  width: auto;
  height: 32px;
  margin-bottom: 32px;
}

.hello,
.userName {
  font-weight: 600;
}

.card {
  background: #f0f3f6;
  padding: 8px 16px 0;
  border: 0;
  overflow: visible;
}

.cardContent:last-child {
  padding-top: 0;
  padding-bottom: 16px;
}
