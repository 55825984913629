.button {
  padding: 12px 24px;
}

.content [class*="MuiTypography"] {
  font-size: 0.875rem;
  display: flex;
}

.nameData,
.last4Data {
  padding-right: 8px;
}

.nameData {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chip {
  background-color: var(--primary-color);
  color: #fff;
}

.avatar {
  background-color: var(--main-background);
}
