.paper {
  width: 100%;
}

.radioGroup {
  display: flex;
  flex-direction: row;
}

.radioButton {
  width: 100%;
}
