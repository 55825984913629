.host {
  background-color: var(--appbar-background);
  color: #fff;
  text-align: center;
  padding: 20px 0;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.menu {
  list-style: none;
  padding: 0;
  margin: 0 8px;
  display: flex;
}

.menu > li {
  position: relative;
  font-size: 0.75rem;
  margin: 0 12px;
}

.menu > li:not(:last-child)::after {
  content: '|';
  position: absolute;
  right: -14px;
  top: 0;
  color: #fff;
}

.link {
  color: #fff;
  text-decoration: none;
  font-family: var(--font-secondary);
}

.link:hover,
.link:focus {
  text-decoration: underline;
}
