.host {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
}

.controlsWrapper {
  padding: 16px;
  overflow-y: auto;
  width: 100%;
  margin: 0;
}

.buttonWrapper {
  margin-top: auto;
  width: 100%;
}

.buttons {
  padding: 24px;
}
