.container {
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  flex-shrink: 0;
}

.listWrapper {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-top: 0;
}

.listWrapper a:nth-child(even) {
  background-color: var(--even-row-background);
}

.buttonWrapper {
  padding: 16px 24px;
}

.button {
  margin-bottom: auto;
  padding: 8px 24px;
  border-width: 2px;
}

.noDataText {
  padding: 16px;
  text-align: center;
}
