.title {
  padding-bottom: 0;
}

.container {
  width: 100%;
  padding: 0 16px;
}

.heading {
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 16px;
}

.paragraph {
  white-space: pre-line;
}

.actions {
  margin-bottom: 40px;
}

.button {
  background-color: var(--error-color);
}

@media (min-width: 600px) {
  .container {
    padding: 0 72px;
  }
}
